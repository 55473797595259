import React from 'react';

const LocationPin = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="15"
      viewBox="0 0 12 15"
      fill="none"
      className={className}
    >
      <path
        d="M10.2427 10.5761L6 14.8188L1.75734 10.5761C0.918228 9.73699 0.346791 8.66789 0.115286 7.50401C-0.11622 6.34013 0.00260456 5.13373 0.456732 4.03738C0.91086 2.94103 1.6799 2.00396 2.66659 1.34467C3.65328 0.685388 4.81332 0.333496 6 0.333496C7.18669 0.333496 8.34672 0.685388 9.33342 1.34467C10.3201 2.00396 11.0891 2.94103 11.5433 4.03738C11.9974 5.13373 12.1162 6.34013 11.8847 7.50401C11.6532 8.66789 11.0818 9.73699 10.2427 10.5761V10.5761ZM6 7.66678C6.35362 7.66678 6.69276 7.5263 6.94281 7.27625C7.19286 7.0262 7.33334 6.68707 7.33334 6.33344C7.33334 5.97982 7.19286 5.64068 6.94281 5.39063C6.69276 5.14059 6.35362 5.00011 6 5.00011C5.64638 5.00011 5.30724 5.14059 5.05719 5.39063C4.80714 5.64068 4.66667 5.97982 4.66667 6.33344C4.66667 6.68707 4.80714 7.0262 5.05719 7.27625C5.30724 7.5263 5.64638 7.66678 6 7.66678Z"
        fill="#162E5A"
      />
    </svg>
  );
};

export default LocationPin;
