import React from 'react';

const ArrowIcon = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M5 5.33331L0 0.333313H10L5 5.33331Z" fill="#232E40" />
    </svg>
  );
};

export default ArrowIcon;
