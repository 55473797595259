import React from 'react';

const CommonDeskLogo = () => {
  return (
    <svg
      width="180"
      height="40"
      viewBox="0 0 180 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Common Desk</title>
      <g clipPath="url(#clip0_5_2)">
        <path
          d="M2.6421 0.294863C1.86119 0.451046 1.39265 0.73738 0.88505 1.32306C0.0781091 2.26015 0.0911241 2.22111 0.0390634 9.13217C1.78702e-05 13.271 0.0390634 15.7309 0.13017 16.3556C0.312382 17.592 0.767914 18.3079 1.69199 18.8285L2.40783 19.232L6.41651 19.271C11.102 19.3231 11.5315 19.258 12.4425 18.3339C13.2104 17.579 13.4057 16.8241 13.4057 14.6506V12.9196H11.8438H10.282V14.3513C10.282 15.7048 10.269 15.809 9.95664 16.1083L9.64427 16.4337H6.70284C3.94362 16.4337 3.76141 16.4207 3.51412 16.1734C3.26683 15.9261 3.25382 15.7439 3.25382 9.74389C3.25382 2.93694 3.21477 3.24931 4.07377 3.09313C4.30805 3.04106 5.68766 3.02805 7.13234 3.05408C10.4252 3.10614 10.282 3.00202 10.282 5.12349V6.54215H11.8438H13.4057V4.81113C13.4057 2.16905 13.0022 1.23196 11.5965 0.568182L10.8677 0.229788L7.02822 0.203757C4.91976 0.190742 2.94145 0.229788 2.6421 0.294863Z"
          fill="white"
        />
        <path
          d="M19.5228 0.372964C18.6507 0.607238 18 1.10181 17.5705 1.86971L17.2451 2.44238L17.2061 9.52264C17.18 15.6528 17.193 16.681 17.3753 17.2146C17.6095 17.9174 18.1692 18.5422 18.9241 18.9456C19.4317 19.219 19.6399 19.232 23.6616 19.271C28.321 19.3231 28.8026 19.258 29.6876 18.5291C29.9479 18.3079 30.2993 17.8524 30.4685 17.514L30.7809 16.8892L30.8199 10.1864C30.846 5.59205 30.8199 3.27535 30.7158 2.81982C30.4815 1.75257 29.9609 1.03674 29.0629 0.607238L28.308 0.229797L24.2733 0.203767C21.0065 0.177736 20.0954 0.203767 19.5228 0.372964ZM27.423 3.32741C27.6442 3.53565 27.6573 4.0042 27.6963 9.67882L27.7353 15.7829L27.41 16.1083L27.0846 16.4337H23.9479C20.9935 16.4337 20.8113 16.4207 20.564 16.1734C20.3167 15.9261 20.3037 15.7439 20.3037 9.75691C20.3037 4.04324 20.3167 3.5747 20.5379 3.39249C20.9154 3.05409 21.0586 3.04108 24.1692 3.06711C26.8763 3.09314 27.2147 3.11917 27.423 3.32741Z"
          fill="white"
        />
        <path
          d="M35.0109 9.73088V19.297H36.4425H37.8742V12.438C37.8742 8.66363 37.9263 5.6311 37.9783 5.70919C38.0434 5.77426 39.1106 8.2081 40.3601 11.1235L42.6247 16.4337H43.8612H45.0976L47.3753 11.0975C48.6247 8.16906 49.692 5.76125 49.7571 5.76125C49.8091 5.76125 49.8482 8.8068 49.8482 12.5291V19.297H51.41H52.9718L52.9979 12.5031L53.0369 5.7222L55.3146 11.0454L57.6052 16.3686L58.8547 16.4077L60.0911 16.4467L61.6009 12.9586C62.4208 11.0454 63.4751 8.61157 63.9306 7.54433L64.7506 5.6311L64.7896 12.4641L64.8156 19.297H66.3124H67.8091L67.8352 9.86103C67.8612 4.66797 67.8612 0.359946 67.8352 0.29487C67.8091 0.216779 67.0933 0.164718 66.0521 0.164718H64.3211L61.692 6.11266C60.2473 9.39248 59.0239 12.1127 58.9718 12.1647C58.9328 12.2168 57.6963 9.54867 56.2256 6.24281L53.5445 0.229794H51.41H49.2885L46.6074 6.24281C45.1367 9.54867 43.9133 12.2298 43.8742 12.2038C43.8482 12.1647 42.6247 9.45756 41.1801 6.17773L38.538 0.229794L36.7809 0.190749L35.0109 0.151703V9.73088Z"
          fill="white"
        />
        <path
          d="M74.4208 0.346946C73.4707 0.60725 72.807 1.14087 72.4035 1.97384L72.0391 2.70269V9.73089V16.7591L72.3905 17.501C72.807 18.399 73.5228 18.9587 74.5119 19.1669C74.9805 19.258 76.6334 19.2971 79.2885 19.271L83.3623 19.232L84.0781 18.8285C85.0542 18.2819 85.4577 17.5921 85.6529 16.2385C85.8612 14.6636 85.7441 2.98903 85.5098 2.33827C85.2625 1.63545 84.5727 0.880569 83.8438 0.529159C83.2451 0.242825 83.141 0.22981 79.1323 0.203779C76.0868 0.177749 74.8764 0.216795 74.4208 0.346946ZM82.243 3.34044C82.5163 3.58773 82.5163 3.6528 82.5163 9.75692C82.5163 15.7439 82.5033 15.9261 82.256 16.1734C82.0087 16.4207 81.8265 16.4337 78.885 16.4337C76.0217 16.4337 75.7484 16.4207 75.4751 16.1864L75.1627 15.9391L75.1237 10.2255C75.0976 7.08881 75.1237 4.27753 75.1627 3.96517C75.2929 3.06712 75.449 3.02807 78.9501 3.06712C81.7093 3.09315 81.9957 3.11918 82.243 3.34044Z"
          fill="white"
        />
        <path
          d="M89.5445 9.73089V19.2971H91.0412H92.538L92.564 12.2689L92.6031 5.25367L96.7028 12.2689L100.803 19.2971H102.403H103.991V9.73089V0.164734H102.495H100.998L100.972 7.0758L100.933 13.9999L96.8981 7.08881L92.8634 0.164734H91.2104H89.5445V9.73089Z"
          fill="white"
        />
        <path
          d="M115.054 9.71786V19.297H120.299C126 19.297 126.338 19.258 127.236 18.5812C127.484 18.399 127.848 17.9304 128.043 17.566L128.395 16.8892L128.434 10.1994C128.46 5.73522 128.421 3.28836 128.33 2.83283C128.108 1.76559 127.575 1.03674 126.677 0.607235L125.922 0.229794L120.495 0.190749L115.054 0.151703V9.71786ZM125.037 3.32741C125.258 3.53565 125.271 3.97817 125.271 9.74389V15.9391L124.959 16.1864C124.685 16.4207 124.412 16.4337 121.419 16.4337H118.178V9.73088V3.01504L121.497 3.05409C124.464 3.09314 124.829 3.11917 125.037 3.32741Z"
          fill="white"
        />
        <path
          d="M132.625 9.73089V19.2971H138.742H144.859V17.8654V16.4337H140.304H135.748V13.7005V10.9673H139.523H143.297V9.53567V8.104H139.523H135.748V5.56604V3.02807H140.304H144.859V1.5964V0.164734H138.742H132.625V9.73089Z"
          fill="white"
        />
        <path
          d="M150.56 0.346943C149.623 0.594232 148.946 1.11484 148.542 1.89575C148.178 2.57254 148.178 2.57254 148.178 5.24065C148.178 7.72655 148.191 7.94781 148.464 8.42937C149.154 9.71788 149.922 10.0693 154.178 11.0324C155.961 11.4359 157.51 11.8263 157.64 11.8914C158.056 12.1127 158.134 12.4901 158.134 14.1951C158.134 16.6289 158.33 16.4858 154.985 16.5378C152.069 16.5899 151.497 16.4988 151.262 15.9782C151.171 15.796 151.106 15.1452 151.106 14.5465V13.4402H149.61H148.113V15.0151C148.113 16.9543 148.256 17.4879 149.024 18.2689C149.948 19.232 150.325 19.2971 154.685 19.2971C158.772 19.2971 159.332 19.219 160.165 18.4901C161.102 17.6702 161.193 17.3318 161.232 14.4294C161.284 11.3057 161.206 10.9023 160.347 10.0433C159.566 9.26235 158.993 9.0541 155.323 8.24716C153.683 7.88274 152.147 7.49228 151.913 7.38816C151.341 7.12785 151.236 6.77644 151.236 5.12352C151.236 3.90009 151.275 3.66582 151.51 3.36647L151.77 3.02807H154.568C158.004 3.02807 157.874 2.963 157.874 4.72005V5.89141H159.449H161.024L160.985 4.29054C160.933 2.83284 160.907 2.63762 160.542 1.92178C160.087 1.01072 159.384 0.503126 158.278 0.294883C157.262 0.0996545 151.315 0.1387 150.56 0.346943Z"
          fill="white"
        />
        <path
          d="M164.902 9.73088V19.297H166.529H168.156V15.1322V10.9673H169.041H169.939L172.894 15.1322L175.861 19.297H177.722H179.597L176.095 14.4814C174.156 11.8393 172.581 9.61374 172.581 9.53565C172.581 9.47058 174.104 7.32307 175.952 4.78511L179.336 0.164718H177.54H175.744L172.829 4.13435L169.926 8.10398H169.041H168.156L168.13 4.16038L168.091 0.229794L166.503 0.190749L164.902 0.151703V9.73088Z"
          fill="white"
        />
        <path
          d="M113.011 25.6615C112.269 25.9218 111.67 26.1951 111.67 26.2732C111.67 26.3383 111.852 26.5335 112.061 26.6897L112.451 26.989V31.3361C112.451 35.527 112.438 35.7092 112.191 35.9565C112.048 36.0996 111.774 36.2168 111.592 36.2168C111.345 36.2168 111.267 36.2688 111.306 36.438C111.345 36.6463 111.553 36.6723 113.557 36.6723C115.38 36.6723 115.77 36.6333 115.77 36.4901C115.77 36.386 115.562 36.2298 115.315 36.1517C114.807 35.9825 114.703 35.7222 114.677 34.5248C114.664 33.7179 114.794 33.4055 115.106 33.5226C115.302 33.6007 116.993 35.8003 117.007 35.9825C117.007 36.0346 116.863 36.1127 116.668 36.1647C116.46 36.2168 116.356 36.3209 116.382 36.4641C116.421 36.6463 116.707 36.6723 118.738 36.6853C120.833 36.6983 121.041 36.6723 121.041 36.4641C121.041 36.2298 120.794 36.0866 120.39 36.0866C120.247 36.0866 119.935 35.9044 119.675 35.6832C119.115 35.1886 117.267 32.6766 117.267 32.4163C117.267 32.0129 118.829 30.9066 119.701 30.6853C120.169 30.5682 120.325 30.4641 120.325 30.2819C120.325 30.1517 120.247 30.0476 120.156 30.0736C120.065 30.0866 119.167 30.1387 118.165 30.1907C116.551 30.2558 116.356 30.2949 116.356 30.5031C116.356 30.6463 116.486 30.7634 116.746 30.8155C116.954 30.8675 117.163 30.9456 117.202 31.0107C117.241 31.0758 116.902 31.5183 116.46 31.9868C115.77 32.7157 115.562 32.8589 115.184 32.8719L114.729 32.8979L114.716 29.0584C114.716 25.8307 114.677 25.2189 114.521 25.2059C114.416 25.1929 113.74 25.4012 113.011 25.6615Z"
          fill="white"
        />
        <path
          d="M108.325 30.0996C108.039 30.2298 107.466 30.438 107.063 30.5682C106.256 30.8285 106.152 31.0237 106.659 31.4272C106.985 31.6745 106.985 31.7005 106.985 33.7439C106.985 35.9825 106.92 36.2168 106.256 36.2168C106.035 36.2168 105.944 36.2818 105.944 36.4771C105.944 36.7374 106.009 36.7374 108.26 36.7113C110.187 36.6723 110.564 36.6463 110.564 36.4771C110.564 36.3599 110.382 36.2558 110.069 36.2038C109.796 36.1647 109.51 36.0606 109.445 35.9955C109.302 35.8523 109.262 31.7526 109.393 31.5313C109.523 31.3231 109.887 31.3881 110.239 31.6615C110.668 31.9999 111.462 31.9999 111.8 31.6615C112.139 31.3231 112.139 30.6072 111.826 30.3209C111.436 29.9695 110.655 30.0476 109.991 30.4771C109.666 30.6983 109.354 30.8285 109.302 30.7894C109.236 30.7504 109.197 30.5421 109.223 30.3209C109.275 29.8133 109.08 29.7612 108.325 30.0996Z"
          fill="white"
        />
        <path
          d="M62.2776 30.2168C61.2755 30.399 60.6247 30.9066 60.2603 31.7916C60.0651 32.2602 60.0911 32.2992 60.7419 32.4554C61.4447 32.6116 61.8221 32.5986 61.8221 32.4033C61.8481 31.5704 63.5792 31.3361 63.9306 32.13C64.1518 32.6116 63.8915 32.7808 62.4859 33.0281C61.0802 33.2623 60.5076 33.5617 60.2082 34.1864C59.7657 35.1235 60.0911 36.1777 60.9891 36.7114C61.731 37.1409 63.0195 37.0758 63.6963 36.5682L64.1648 36.2038V36.5292C64.1648 36.8545 64.1909 36.8675 64.9848 36.8675H65.7917L65.7527 34.4337C65.7137 32.2211 65.6876 31.9348 65.4273 31.4012C64.9588 30.4511 63.6833 29.9695 62.2776 30.2168ZM63.9956 34.4728C63.8915 35.0845 63.462 35.54 62.9024 35.6441C62.0824 35.8003 61.5358 35.0975 61.9913 34.4858C62.1475 34.2645 62.9544 34.0433 63.6963 34.0172C64.0477 34.0042 64.0607 34.0172 63.9956 34.4728Z"
          fill="white"
        />
        <path
          d="M82.1779 30.36C79.9262 31.245 79.2104 34.0823 80.7722 35.8263C82.1388 37.3361 85.1583 37.245 86.3037 35.6571C86.5119 35.3708 86.6811 35.0845 86.6811 35.0194C86.6811 34.8112 86.2516 34.7461 86.1085 34.9283C85.7701 35.3708 85.3145 35.566 84.5987 35.566C83.2711 35.553 82.4512 34.9543 82.1258 33.7699C81.8915 32.8589 81.9566 32.8328 84.3644 32.8328C86.6421 32.8328 86.6551 32.8329 86.4989 31.9999C86.3818 31.3621 85.9132 30.7504 85.3145 30.4381C84.5727 30.0476 83.0629 30.0086 82.1779 30.36ZM84.2733 31.0758C84.6247 31.4142 84.6898 31.7786 84.4425 32.0259C84.3514 32.117 83.8829 32.1821 83.2711 32.1821C82.308 32.1821 82.256 32.1691 82.256 31.8827C82.269 31.5183 82.5032 31.1929 82.9848 30.9456C83.5184 30.6723 83.9089 30.6984 84.2733 31.0758Z"
          fill="white"
        />
        <path
          d="M101.05 30.2819C99.5792 30.7504 98.6551 31.8697 98.551 33.3534C98.4078 35.5009 99.9306 36.8675 102.495 36.8675C105.006 36.8675 106.464 35.6181 106.464 33.4576C106.464 31.8827 105.787 30.9196 104.278 30.3599C103.471 30.0606 101.896 30.0215 101.05 30.2819ZM103.054 30.9586C103.627 31.3491 103.9 32.143 103.952 33.5487C104.004 34.72 103.978 34.9153 103.705 35.4359C103.38 36.0736 102.95 36.3079 102.377 36.1647C101.649 35.9825 101.167 35.0714 101.037 33.6007C100.868 31.5834 101.935 30.1777 103.054 30.9586Z"
          fill="white"
        />
        <path
          d="M126.508 30.2428C124.816 30.5812 123.683 32.4163 124.035 34.2385C124.191 35.0844 124.412 35.527 124.972 36.0736C125.61 36.7113 126.078 36.9066 127.106 36.9717C128.473 37.0628 129.319 36.6983 130.009 35.7222C130.542 34.9673 130.503 34.8372 129.644 34.5508L128.863 34.2905L128.59 34.733C127.978 35.7222 126.456 35.566 125.935 34.4597C125.206 32.9239 126.625 31.245 128.043 31.9738C128.239 32.0779 128.486 32.3122 128.59 32.4944C128.785 32.8849 128.902 32.8979 129.514 32.6506C129.761 32.5595 130.061 32.4424 130.191 32.4033C130.412 32.3252 130.412 32.2862 130.204 31.8957C129.54 30.5552 128.095 29.9174 126.508 30.2428Z"
          fill="white"
        />
        <path
          d="M133.783 30.2298C132.729 30.412 131.714 31.245 131.31 32.2472C131.232 32.4684 131.154 33.0541 131.154 33.5617C131.141 35.6441 132.469 36.9977 134.512 36.9977C135.944 36.9977 136.946 36.399 137.531 35.2016C137.818 34.6029 137.883 34.3036 137.883 33.5487C137.857 31.258 136.1 29.8394 133.783 30.2298ZM135.332 32.0519C136.542 32.7938 136.308 34.9153 134.967 35.3187C133.965 35.6181 133.041 34.9673 132.924 33.848C132.794 32.7548 133.536 31.7916 134.512 31.7916C134.733 31.7916 135.098 31.9088 135.332 32.0519Z"
          fill="white"
        />
        <path
          d="M142.204 30.2949C141.879 30.386 141.449 30.5942 141.28 30.7504L140.954 31.0498V30.7114C140.954 30.36 140.941 30.36 140.108 30.36H139.262V33.6137V36.8675H140.174H141.085V34.707V32.5465L141.462 32.1691C142.139 31.5053 143.037 31.7526 143.297 32.6767C143.362 32.937 143.427 33.9912 143.427 35.0064V36.8675H144.273H145.119V34.9283C145.119 33.8741 145.184 32.8198 145.262 32.5986C145.497 31.9088 146.174 31.6224 146.85 31.9348C147.384 32.1691 147.462 32.5725 147.462 34.7851V36.8675H148.308H149.154V34.4077C149.154 32.976 149.102 31.7786 149.011 31.5704C148.685 30.6853 147.892 30.2038 146.798 30.2168C146.004 30.2168 145.471 30.412 145.067 30.8675L144.807 31.1409L144.403 30.7504C143.896 30.2558 143.063 30.0866 142.204 30.2949Z"
          fill="white"
        />
        <path
          d="M160.738 30.2168C159.774 30.425 159.137 30.9326 158.785 31.7916C158.59 32.2602 158.616 32.2992 159.267 32.4554C159.97 32.6116 160.347 32.5986 160.347 32.4033C160.347 32.2992 160.49 32.091 160.659 31.9348C161.193 31.4272 162.312 31.5834 162.495 32.1951C162.638 32.6246 162.338 32.7938 161.011 33.0281C160.334 33.1322 159.592 33.3534 159.358 33.4836C158.512 34.0172 158.278 35.3317 158.902 36.1907C159.527 37.0628 161.297 37.258 162.221 36.5552L162.69 36.2038V36.5291C162.69 36.8545 162.716 36.8675 163.51 36.8675H164.317L164.278 34.4337C164.239 32.2211 164.213 31.9348 163.952 31.4012C163.757 30.9977 163.497 30.7244 163.145 30.5291C162.56 30.2168 161.427 30.0736 160.738 30.2168ZM162.521 34.4858C162.377 35.3448 161.414 35.9174 160.751 35.553C160.36 35.3317 160.256 34.8372 160.503 34.4858C160.672 34.2645 161.466 34.0432 162.221 34.0172C162.586 34.0042 162.586 34.0172 162.521 34.4858Z"
          fill="white"
        />
        <path
          d="M68.1215 30.3469C67.9653 30.5031 68.1084 30.7504 68.3818 30.8285C68.564 30.8675 68.8113 31.0237 68.9414 31.1539C69.0716 31.297 69.7874 32.6506 70.5423 34.1734C71.7527 36.6072 71.9479 36.9326 72.2212 36.9326C72.4555 36.9326 72.5987 36.7894 72.8329 36.3469C73.0021 36.0215 73.4577 35.1495 73.8481 34.4077L74.564 33.0411L75.488 34.9283C75.9956 35.9565 76.4902 36.8545 76.5943 36.9326C76.9457 37.1669 77.193 36.8285 78.1431 34.72C79.692 31.3231 79.8351 31.0497 80.3687 30.8805C80.6551 30.7894 80.8243 30.6463 80.8243 30.5031C80.8243 30.2949 80.6681 30.2688 79.3275 30.2688C77.9739 30.2558 77.8308 30.2819 77.8308 30.4901C77.8308 30.6333 77.987 30.7764 78.2212 30.8675C78.4425 30.9326 78.6117 31.0888 78.6117 31.1929C78.6117 31.4272 77.5835 34.1343 77.4924 34.1343C77.3492 34.1343 76.1258 31.3881 76.1648 31.1799C76.1909 31.0628 76.3991 30.9196 76.6334 30.8545C76.8937 30.7764 77.0499 30.6593 77.0499 30.5161C77.0499 30.3079 76.7375 30.2949 72.6117 30.2949C70.1779 30.2949 68.1605 30.3209 68.1215 30.3469ZM73.731 31.2059C73.9132 31.3751 74.0564 31.6484 74.0564 31.8176C74.0564 32.13 73.1583 34.1343 73.0152 34.1343C72.846 34.1343 71.5575 31.271 71.6225 31.0758C71.6876 30.9326 71.9089 30.8805 72.5596 30.8805C73.3015 30.8805 73.4577 30.9196 73.731 31.2059Z"
          fill="white"
        />
        <path
          d="M86.6812 30.4641C86.6812 30.6072 86.8503 30.7764 87.1107 30.8936C87.4751 31.0498 87.6833 31.4012 88.9588 33.9782C90.3514 36.7894 90.4035 36.8675 90.7549 36.8415C91.0933 36.8025 91.2104 36.6333 92.0174 35.0584C92.512 34.0953 92.9675 33.2623 93.0326 33.1973C93.0976 33.1322 93.5402 33.8871 94.0738 34.9803C94.9458 36.7504 95.0239 36.8675 95.3753 36.8675C95.7007 36.8675 95.7788 36.7764 96.0521 36.1127C96.5857 34.8111 98.2647 31.3621 98.4729 31.1278C98.59 30.9977 98.8503 30.8545 99.0586 30.8155C99.3189 30.7504 99.436 30.6463 99.436 30.4771C99.436 30.2428 99.3319 30.2298 97.9393 30.2298C96.5597 30.2298 96.4425 30.2428 96.4425 30.4771C96.4425 30.6333 96.5857 30.7764 96.846 30.8675C97.1844 30.9847 97.2235 31.0498 97.1584 31.297C96.9631 31.9218 96.1692 33.9391 96.0651 34.0432C95.948 34.1604 94.7766 31.7265 94.7245 31.271C94.6855 31.0107 94.7636 30.9456 95.1801 30.8415C95.5185 30.7634 95.6616 30.6593 95.6616 30.4771C95.6616 30.2428 95.4924 30.2298 91.1714 30.2298C86.9154 30.2298 86.6812 30.2428 86.6812 30.4641ZM92.2777 31.1669C92.6551 31.5834 92.6031 32.1951 92.0825 33.2233C91.8352 33.7309 91.6009 34.1344 91.5618 34.1344C91.4707 34.1344 90.4556 32.104 90.2603 31.5183C90.1562 31.245 90.1172 30.9977 90.1562 30.9586C90.2083 30.9196 90.6378 30.8806 91.1193 30.8806C91.8612 30.8806 92.0434 30.9326 92.2777 31.1669Z"
          fill="white"
        />
        <path
          d="M153.202 30.5552C152.525 30.9456 152.538 30.9456 152.538 30.6202C152.538 30.386 152.447 30.3599 151.692 30.3599H150.846V34.8502V39.3404H151.757H152.668V37.8437V36.3339L153.033 36.6072C153.488 36.9456 154.62 37.0758 155.375 36.8806C156.104 36.6723 156.807 36.0476 157.171 35.2406C157.796 33.9131 157.484 31.7786 156.56 30.9196C155.74 30.1647 154.191 29.9955 153.202 30.5552ZM154.985 32.0519C155.779 32.5335 156.026 33.8871 155.466 34.733C155.141 35.2406 154.425 35.5009 153.84 35.3187C153.072 35.0845 152.759 34.6419 152.694 33.7048C152.642 32.963 152.668 32.8458 153.007 32.4554C153.605 31.7526 154.256 31.6094 154.985 32.0519Z"
          fill="white"
        />
        <path
          d="M168.677 30.373C168.495 30.4511 168.221 30.6333 168.065 30.7764L167.766 31.0498V30.7114C167.766 30.36 167.753 30.36 166.92 30.36H166.074V33.6138V36.8676H166.985H167.896V34.707V32.5465L168.273 32.1691C168.534 31.9088 168.781 31.7916 169.067 31.7916C169.354 31.7916 169.601 31.9088 169.861 32.1691L170.239 32.5465V34.707V36.8676H171.163H172.087L172.048 34.3556C171.996 31.9088 171.983 31.8437 171.644 31.258C171.423 30.8936 171.111 30.5682 170.837 30.4381C170.343 30.2038 169.184 30.1778 168.677 30.373Z"
          fill="white"
        />
        <path
          d="M172.842 30.425C172.842 30.451 173.427 31.7005 174.143 33.1712C174.859 34.655 175.445 35.9435 175.445 36.0215C175.445 36.1127 175.236 36.6463 174.976 37.2059C174.716 37.7786 174.39 38.4814 174.26 38.7808L174.026 39.3404H174.976H175.926L176.876 37.2189C177.41 36.0606 178.321 34.0432 178.907 32.7287L179.987 30.3469L178.998 30.386L178.022 30.425L177.293 32.143C176.902 33.0931 176.538 33.874 176.486 33.874C176.434 33.874 176.043 33.0931 175.614 32.143L174.833 30.425L173.844 30.386C173.284 30.3599 172.842 30.386 172.842 30.425Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_5_2">
          <rect width="180" height="39.375" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CommonDeskLogo;
