import React from 'react';
import cs from 'classnames';
import styles from './OpenButton.module.scss';

const OpenButton = ({
  label,
  href = '',
  target = '_blank',
  onClick,
  className,
  isFilterButton,
}: any) => {
  if (href) {
    return (
      <a
        href={href || '#'}
        target={target}
        className={cs(
          className,
          'relative z-[1] pt-2 px-4 pb-2 overflow-hidden border border-solid border-light-brown rounded-md text-black hover:text-white before:absolute before:inset-0 before:w-full before:h-full before:bg-light-brown before:z-[-1] ',
          styles.openButton,
        )}
        onClick={onClick}
      >
        {label}
      </a>
    );
  }
  return (
    <div
      className={cs(
        className,
        'relative z-[1] pt-2 px-4 pb-2 overflow-hidden border border-solid border-light-brown rounded-md text-black leading-none cursor-pointer hover:text-white before:absolute before:inset-0 before:w-full before:h-full before:bg-light-brown before:z-[-1] ',
        styles.openButton,
        { [styles.filterOpenButton]: isFilterButton },
      )}
      onClick={onClick}
    >
      {label}
    </div>
  );
};

export default OpenButton;
