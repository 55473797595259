import React from 'react';
import cs from 'classnames';
import cardStyle from './Card.module.scss';

const CardTitle = ({ title }) => {
  if (!title) {
    return null;
  }
  return (
    <div
      className={cs(
        'not-italic text-xl font-medium text-left text-text-black-1',
        cardStyle.card__title,
      )}
    >
      {title}
    </div>
  );
};

export default CardTitle;
