const cityList = [
  {
    value: 'dallas',
    label: 'Dallas',
    neighborhoodId: 88,
    lat: 32.779167,
    lng: -96.808891,
  },
  {
    value: 'houston',
    label: 'Houston',
    neighborhoodId: 84,
    lat: 29.7507,
    lng: -95.3636,
  },
  {
    value: 'austin',
    label: 'Austin',
    neighborhoodId: 90,
    lat: 30.266666,
    lng: -97.73333,
  },
  {
    value: 'fort-worth',
    label: 'Fort Worth',
    neighborhoodId: 220,
    lat: 32.768799,
    lng: -97.309341,
  },
  {
    value: 'raleigh',
    label: 'Raleigh',
    neighborhoodId: 87,
    lat: 35.784766,
    lng: -78.625053,
  },
  {
    value: 'wilmington',
    label: 'Wilmington',
    neighborhoodId: 85,
    lat: 34.225727,
    lng: -77.94471,
  },
];

export default cityList;
