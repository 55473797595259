import React from 'react';
import SmallEclipse from '../../../../assets/SmallEclipse';

const Distance = ({ text }) => {
  return (
    <div className="flex items-center text-base font-medium pl-1 text-dark-blue">
      <SmallEclipse className="mr-1 mt-1" /> {text || 0} miles away
    </div>
  );
};

export default Distance;
