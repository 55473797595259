import React, {
  SyntheticEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import cs from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import { IDropdownProps, Option } from './Dropdown.types';
import dropdownStyle from './Dropdown.module.scss';
import NoResult from './NoResult';
import {
  getFormattedOptions,
  treeToFlatList,
} from '../../../../helpers/common';
import DropdownOption from './DropdownOption';
import OpenButton from '../OpenButton';

const Dropdown = ({
  id = '',
  label = '',
  wrapperClassName = '',
  options = [],
  onChange,
  value,
  isMultiSelect = true,
  labelField = 'label',
  valueField = 'value',
  filterKey = '',
  isGrouping = false,
}: IDropdownProps) => {
  const formattedOptions: Option[] = useMemo(
    () => getFormattedOptions(options, labelField, valueField),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options],
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const scrollRef = useRef<any>(null);

  useEffect(() => {
    const el = document.getElementById('scroll');
    if (el) {
      if (isDropdownOpen) {
        el.style.overflow = 'hidden';
        scrollRef?.current?.scrollTo({ top: 0 });
      } else {
        el.style.overflow = 'scroll';
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDropdownOpen]);

  const onDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const onMultiSelectClick =
    (item: Option, isSelected: boolean) => (e: SyntheticEvent) => {
      e.stopPropagation();
      e.preventDefault();

      if (value && value?.length && isSelected) {
        onChange(filterKey, [
          ...value.filter((v: Option) => v.value !== item.value),
        ]);
      } else {
        onChange(filterKey, value && value.length ? [...value, item] : [item]);
      }
    };

  const onSingleSelectClick = (optValue: string, item: Option) => () => {
    onChange(filterKey, optValue === value?.value ? null : item);
    setIsDropdownOpen(false);
  };

  const onGroupParentSelectClick =
    (item: Option, isSelected: boolean) => (e: SyntheticEvent) => {
      e.stopPropagation();
      e.preventDefault();

      if (item.children && !isSelected) {
        const allOptions = treeToFlatList(item.children || []);

        onChange(filterKey, [
          { label: item.label, value: item.value },
          ...value,
          ...allOptions,
        ]);
      } else if (
        item &&
        item.children &&
        value &&
        value?.length &&
        isSelected
      ) {
        const children = [
          item.value,
          item.parent,
          ...treeToFlatList(item.children || []).map((v: Option) => v.value),
        ];

        onChange(filterKey, [
          ...value.filter((v: Option) => !children.includes(v.value)),
        ]);
      }
    };

  const renderDropdownOptions = (dropdownOptions: Option[], className = '') => {
    // eslint-disable-next-line
    let dropdownList: any = [];

    for (let i = 0; i < dropdownOptions.length; i += 1) {
      const item = { ...dropdownOptions[i] };
      const isSelected =
        value?.length &&
        value.findIndex(
          (d: Option) => d.value === item.value && d.label === item.label,
        ) > -1;

      // @ts-ignore
      dropdownList.push(
        <DropdownOption
          key={item.value}
          isMultiSelect={isMultiSelect}
          item={item}
          value={value}
          onMultiSelectClick={onMultiSelectClick}
          onSingleSelectClick={onSingleSelectClick}
          isSelected={isSelected}
          className={className}
          isIndeterminate={false}
        />,
      );
    }
    return [...dropdownList];
  };

  const renderGroupingDropdownOptions = (
    dropdownOptions: Option[],
    childLevel = 0,
  ) => {
    // eslint-disable-next-line
    let dropdownList: any = [];
    const selectedValues = value.map((v: Option) => v.value);

    for (let i = 0; i < dropdownOptions.length; i += 1) {
      const item = { ...dropdownOptions[i] };
      const isSelected =
        value?.length &&
        value.findIndex(
          (d: Option) => d.value === item.value && d.label === item.label,
        ) > -1;
      const isIndeterminate =
        item.children && item.children.length && !isSelected
          ? item.children.some(child => selectedValues.includes(child.value))
          : false;

      const isChecked =
        item.children && item.children.length && !isSelected
          ? item.children.every(child => selectedValues.includes(child.value))
          : false;

      dropdownList.push(
        <DropdownOption
          key={item.value}
          isMultiSelect={isMultiSelect}
          item={item}
          value={value}
          onMultiSelectClick={onGroupParentSelectClick}
          onSingleSelectClick={onSingleSelectClick}
          isSelected={isIndeterminate ? isChecked || false : isSelected}
          // @ts-ignore
          childLevel={childLevel}
          isIndeterminate={isChecked ? false : isIndeterminate}
        />,
      );
      if (item.children && item.children.length) {
        dropdownList.push(
          renderGroupingDropdownOptions(item.children, childLevel + 1),
        );
      }
    }
    return [...dropdownList];
  };

  return (
    <div
      className={cs(
        'flex items-center relative select-none',
        dropdownStyle.selectorWrapper,
        wrapperClassName,
      )}
      id={id}
    >
      <div
        className={cs(
          'flex items-center pt-2.5 px-4 pb-2 border-solid border-color-[#6f7782] border-[1px] justify-center cursor-pointer box-border flex-wrap rounded-[44px] text-black-1 text-sm',
          dropdownStyle.selectorInner,
          {
            [dropdownStyle.selectorActive]: isDropdownOpen,
          },
        )}
        onClick={onDropdownClick}
      >
        {label}
      </div>
      <OutsideClickHandler
        onOutsideClick={() => isDropdownOpen && onDropdownClick()}
      >
        <div
          className={cs(
            'bg-white list-none absolute opacity-0 ' +
              'invisible pointer-events-none overflow-hidden',
            dropdownStyle.selectorList,
            dropdownStyle.singleSelectorList,
            {
              [dropdownStyle.selectorOpen]: isDropdownOpen,
            },
          )}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}
        >
          <ul
            className={cs(
              'overflow-y-auto max-h-[340px] mb-5',
              dropdownStyle.selectorSubList,
            )}
            ref={scrollRef}
          >
            {/* eslint-disable-next-line no-nested-ternary */}
            {options.length ? (
              isGrouping ? (
                renderGroupingDropdownOptions(formattedOptions, 1)
              ) : (
                renderDropdownOptions(formattedOptions)
              )
            ) : (
              <NoResult />
            )}
          </ul>
          <OpenButton
            label="Close"
            onClick={onDropdownClick}
            className="h-10 absolute z-20 bottom-2 flex items-center justify-center w-full"
          />
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default Dropdown;
