import React from 'react';
import cs from 'classnames';
import cardStyle from './Card.module.scss';
import CardTitle from './CardTitle';
import LocationSize from './LocationSize';
import LocationPin from '../../../../assets/LocationPin';
import Distance from './Distance';
import { iCardProps } from './Card.types';
import OpenButton from '../OpenButton';
import { haversineDistance } from '../../../../helpers/common';

const Card = ({
  data,
  selectedLocation,
  handleSelectItemClick,
  activeTab,
  currentLocation,
}: iCardProps) => {
  let distance: string | number = 0;
  if (currentLocation) {
    distance = haversineDistance(currentLocation, {
      lat: data?.acf?.location?.lat,
      lng: data?.acf?.location?.lng,
    });
  }
  return (
    <div
      className={cs(
        'laptop:flex-[0_0_48.5%] desktop:flex-[0_0_49%] flex-[0_0_100%]',
        cardStyle.card,
      )}
    >
      <div
        className={cs(
          'mt-0 mr-0 ml-0 rounded-md border-4 border-solid relative cursor-pointer h-full ' +
            'transition duration-1000 ease-in-out',
          {
            'border-light-brown': data === selectedLocation,
            'border-transparent': data !== selectedLocation,
          },
        )}
        onClick={() => handleSelectItemClick(data)}
        id={`sideCard-${data?.id}`}
      >
        <div className="relative w-full aspect-video overflow-hidden">
          <img
            className={cs(
              'w-full h-full object-cover duration-1000 transition-transform ease-[cubic-bezier(0.215,0.61,0.355,1)] hover:scale-110',
              cardStyle.card__image,
            )}
            src={data?.acf?.card_image?.url || data?.featured_image_url}
            alt="card_hero"
          />
          {/* {data === selectedLocation ? (
            <img
              src={LargeStarIcon}
              height={40}
              width={40}
              alt="selected-marker"
              className="absolute inset-0.5 m-2"
            />
          ) : null} */}
        </div>
        <div className="desktop:flex items-center justify-between pt-2 laptop:block px-2">
          <CardTitle
            title={(data?.title?.rendered || '-').replace('&#038;', '&')}
          />
          {activeTab === 'office-suite' && data?.sqft?.length
            ? data?.sqft.map(sqft => <LocationSize text={sqft.name} />)
            : null}
        </div>
        <div className="flex items-center justify-between mt-1 px-2">
          {data?.acf?.location?.city ? (
            <div className="flex items-center justify-between">
              <LocationPin className="w-3 mr-2" />
              <span className="not-italic text-base font-bold text-left text-black-1">
                {data?.acf?.location?.city}
              </span>
              {distance ? <Distance text={Math.round(distance)} /> : null}
            </div>
          ) : null}
          {/* <Price value={+data?.acf?.price || 0} /> */}
        </div>
        {data === selectedLocation ? (
          <>
            <div className="absolute top-0 left-0 w-full h-full block transition duration-300 ease-in-out bg-white rounded">
              <div className="flex justify-center items-baseline w-full h-full flex-col p-8 gap-4 mobile:p-4">
                <div className="text-xl font-black mobile:text-xl">
                  {(data?.title?.rendered || '').replace('&#038;', '&')}
                </div>
                {/* <div className="text-base font-sm mobile:text-sm">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                  in tincidunt arcu. Vestibulum ante ipsum.
                </div> */}
                <OpenButton
                  href={data?.link}
                  target="_blank"
                  label="Learn More"
                  className="w-full text-center"
                >
                  Learn More
                </OpenButton>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Card;
