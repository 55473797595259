import React from 'react';
import cs from 'classnames';
import dropdownStyle from './Dropdown.module.scss';

const NoResult = () => {
  return (
    <li
      className={cs(
        'flex relative ml-0 pl-0 z-10 bg-gray-light3 h-10 justify-center items-center mb-5',
      )}
    >
      <div
        className={cs(
          'flex justify-center items-center content-center relative gap-2' +
            'font-medium',
          dropdownStyle.singleSelectorLink,
        )}
      >
        <label className="font-medium text-base text-black">No Results</label>
      </div>
    </li>
  );
};

export default NoResult;
