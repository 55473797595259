import React, { useRef, useEffect } from 'react';
import cs from 'classnames';
import CheckIcon from '../../../../assets/CheckIcon';
import IndeterminateIcon from '../../../../assets/IndeterminateIcon';
import { ICheckboxProps } from './Checkbox.types';

const Checkbox = ({
  id,
  isDisabled,
  label,
  className,
  name,
  checked,
  indeterminate = false,
  ...rest
}: ICheckboxProps) => {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current) {
      // @ts-ignore
      ref.current.indeterminate = indeterminate;
    }
  }, [indeterminate]);

  return (
    <label
      className={cs(
        'flex items-center cursor-pointer relative w-full',
        className,
        {
          'pointer-events-none': isDisabled,
        },
      )}
    >
      <input
        ref={ref}
        type="checkbox"
        id={id}
        name={name}
        {...rest}
        className="absolute w-0 h-0 opacity-0"
      />
      <span
        className={cs(
          'w-5 h-5 bg-white flex items-center justify-center text-white rounded transition duration-300 ease-in-out ' +
            'border-solid border-2 border-gray-light hover:bg-gray-light',
          {
            'bg-gray-light2': isDisabled,
            'border-blue bg-blue hover:bg-blue': checked || indeterminate,
          },
        )}
      >
        {indeterminate ? (
          <IndeterminateIcon
            className={cs('stroke-white stroke-2 opacity-0', {
              'opacity-100': indeterminate,
            })}
          />
        ) : (
          <CheckIcon
            className={cs('stroke-white stroke-2 opacity-0', {
              'opacity-100': checked,
            })}
          />
        )}
      </span>
      <p
        className={cs('text-lg font-medium ml-4 mr-2 my-0 text-gray-medium', {
          'text-gray-light2': isDisabled,
        })}
      >
        {label}
      </p>
    </label>
  );
};

export default Checkbox;
