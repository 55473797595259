import BaseAxios from 'axios';
import { get } from 'lodash';

class Request {
  axios;

  constructor() {
    this.axios = BaseAxios.create({ timeout: 60000 });
  }

  async call(config) {
    const serverBaseUrl = 'https://thecommondesk.com/wp-json/wp/v2';

    try {
      const res = await this.axios.request({
        baseURL: serverBaseUrl,
        ...config,
      });
      return { data: res.data, status: 1 };
    } catch (error: any) {
      const errorStatus = get(error, 'response.status', null);

      // other errors
      if (get(error, 'response', null)) {
        // eslint-disable-next-line prefer-destructuring
        const data = error.response.data;

        if (get(data, 'message') && typeof data.message === 'object') {
          // convert  error message object into string
          let message = '';
          if (!(data.message instanceof Array)) {
            // eslint-disable-next-line guard-for-in
            for (const field in data.message) {
              message = get(data.message, `${field}.0`, '');
              if (message) {
                data.message = message;
                break;
              }
            }
          }
        }

        return { ...data, status: 0, errorStatus };
        // eslint-disable-next-line no-else-return
      } else {
        return {
          status: 0,
          message: 'Please try again later',
          errorStatus,
        };
      }
    }
  }
}

export default new Request();
