import React from 'react';
import cs from 'classnames';
import dropdownStyle from './Dropdown.module.scss';
import Checkbox from '../Checkbox';

const DropdownOption = ({
  isMultiSelect,
  item,
  value,
  onMultiSelectClick,
  onSingleSelectClick,
  isSelected,
  className = 'pl-0',
  childLevel = 0,
  isIndeterminate = false,
}) => {
  return (
    <li
      className={cs(
        `flex relative list-none overflow-hidden cursor-pointer ml-0 z-10 ${className}`,
        {
          'h-10 justify-center items-center mb-2 hover:border-blue hover:bg-blue transition-all duration-300 ease-in-out':
            !isMultiSelect,
          'bg-gray-light3': !isMultiSelect && item.value !== value?.value,
          'border bg-blue border-blue text-white':
            !isMultiSelect && item.value === value?.value,
          'pl-[16px]': childLevel === 2,
          'pl-[32px]': childLevel === 3,
        },
      )}
    >
      {isMultiSelect ? (
        <div
          className={cs(
            'flex justify-between content-center relative no-underline hover:cursor-pointer font-medium',
            dropdownStyle.selectorLink,
          )}
          onClick={onMultiSelectClick(item, isSelected)}
        >
          <Checkbox
            id={`checkbox-${item?.label}`}
            checked={isSelected && value && value?.length}
            indeterminate={isIndeterminate}
            label={
              <span className="font-medium break-words text-black hover:cursor-pointer whitespace-pre-wrap ">
                {item.label}
              </span>
            }
          />
        </div>
      ) : (
        <div
          className={cs(
            'w-full h-full flex justify-center items-center content-center relative no-underline gap-2 cursor-pointer font-medium hover:text-white text-base transition-all duration-300 ease-in-out',
            {
              'text-black': item.value !== value?.value,
            },
          )}
          onClick={onSingleSelectClick(item.value, item)}
        >
          {item.label}
        </div>
      )}
    </li>
  );
};

export default DropdownOption;
