import React, { useEffect, useState } from 'react';
import Map, { Marker, NavigationControl } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';

import SmallStarIcon from '../../../../assets/SmallStarIcon.svg';
import LargeStarIcon from '../../../../assets/LargeStarIcon.svg';

// @ts-ignore
mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const MapView = props => {
  const {
    data,
    selectedLocation,
    handleSelectLocationClick,
    latLngValue,
    zoom: zoomValue,
    mapRef,
  } = props;
  const [mapViewport, setMapViewport] = useState<any>({
    height: '100%',
    width: '100%',
    latitude: latLngValue?.latitude,
    longitude: latLngValue?.longitude,
    zoom: zoomValue || 3.5,
  });

  useEffect(() => {
    setMapViewport({
      ...mapViewport,
      ...(zoomValue ? { zoom: zoomValue } : {}),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomValue]);

  /* useEffect(() => {
    if (selectedLocation) {
      const loc = selectedLocation?.acf?.location || {};
      const coords = loc && loc.lng && loc.lat ? [loc.lng, loc.lat] : [0, 0];
      if (coords) {
        // @ts-ignore
        mapRef?.current?.flyTo({
          center: [coords[0], coords[1]],
          duration: 1000,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation]); */

  const flyToLocation = coords => {
    mapRef?.current?.flyTo({
      center: [coords[0], coords[1]],
      duration: 1000,
    });
  };

  return (
    <div className="flex items-start justify-center w-2/5 mobile:w-full mobile:h-[calc(100vh_-_69%)] h-[calc(100vh_-_148px)] mobile:sticky">
      <Map
        ref={mapRef}
        mapboxAccessToken="pk.eyJ1IjoibWF5b2ppY2giLCJhIjoiY2pla3Q3MzVvMWRoYTJybnVyMndxM2hmdCJ9.nWZlYcpKaMqz6m7xTsnJGA"
        mapStyle="mapbox://styles/mapbox/light-v10"
        onMove={event => {
          const { viewState } = event;
          setMapViewport(viewState);
        }}
        {...mapViewport}
      >
        <NavigationControl />
        {data?.map((location, index: number) => {
          const loc = location?.acf?.location || {};
          const coords =
            loc && loc?.lng && loc?.lat ? [loc?.lng, loc?.lat] : [];
          if (coords.length) {
            return (
              <Marker
                key={index}
                latitude={coords[1]}
                longitude={coords[0]}
                anchor="bottom"
                onClick={e => {
                  flyToLocation(coords);
                  handleSelectLocationClick(e, {
                    location,
                  });
                }}
                style={{ zIndex: selectedLocation === location ? 1 : 0 }}
              >
                {selectedLocation === location ? (
                  <img
                    src={LargeStarIcon}
                    height={48}
                    width={48}
                    alt="Selected Marker"
                  />
                ) : (
                  <img
                    src={SmallStarIcon}
                    height={36}
                    width={36}
                    alt="Marker"
                  />
                )}
              </Marker>
            );
          }
          return null;
        })}
      </Map>
    </div>
  );
};

export default MapView;
