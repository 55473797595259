import React from 'react';
import cs from 'classnames';
import { useMedia } from 'react-use';
import styles from './TabButton.module.scss';
import { ITabsButtonProps } from './Tab.types';

const TabButton = ({ tab, active, onTabClick }: ITabsButtonProps) => {
  const isMobileView = useMedia('(max-width: 768px)');
  return (
    <div
      className={cs('flex flex-col', styles.root, {
        'py-[12px]': isMobileView,
      })}
    >
      <div
        className={cs(
          'p-5 border-solid cursor-pointer transition duration-300 ease-in-out hover:text-light-brown',
          {
            'text-light-brown': active,
            'text-gray': !active,
            'font-medium text-base p-0': isMobileView,
          },
        )}
        onClick={() => onTabClick(tab)}
      >
        {tab?.title}
      </div>
      {!isMobileView ? (
        <div
          className={cs(styles.tabButton, 'h-1 origin-left bg-transparent', {
            [styles['tabButton--activeTab']]: active,
          })}
        />
      ) : null}
    </div>
  );
};

export default TabButton;
