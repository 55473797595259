import React, { useState } from 'react';
import cs from 'classnames';
import { useMedia } from 'react-use';
import OutsideClickHandler from 'react-outside-click-handler';
import { ITabsProps, ITabProps } from './Tab.types';
import styles from './Tabs.module.scss';
import TabButton from './TabButton';
import ArrowIcon from '../../../../assets/ArrowIcon';

const Tabs = ({
  title = '',
  tabs = [],
  /* children, */
  active = 0,
  onChange,
  className,
}: ITabsProps) => {
  const [isTabMenuOpen, setIsTabMenuOpen] = useState(false);
  const onTabClick = (tab: ITabProps) => {
    if (tab?.accessor !== active) {
      onChange?.(tab?.accessor as string);
    }
    setIsTabMenuOpen(false);
  };

  const onTabMenuClick = value => {
    setIsTabMenuOpen(value);
  };

  // @ts-ignore
  const selectedTabTitle = tabs.find(tab => tab?.accessor === active);
  const isMobileView = useMedia('(max-width: 768px)');

  /* let content = null;
  React.Children.forEach(children, child => {
    if (child.props.accessor === active) content = child.props.children;
  }); */

  return (
    <>
      {isMobileView ? (
        <div
          className={cs(
            'relative justify-center w-full h-[64px]',
            styles.tabWrapper,
            className,
          )}
        >
          <div
            className={cs(styles.tabWrapper__tabInner, 'py-6 px-6')}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onTabMenuClick(!isTabMenuOpen);
            }}
            id="workspace-dropdown"
          >
            {/* @ts-ignore */}
            {title}: {selectedTabTitle?.title}
            <ArrowIcon
              className={cs({ [styles.tabWrapper__arrowIcon]: isTabMenuOpen })}
            />
          </div>

          <OutsideClickHandler
            useCapture
            onOutsideClick={e => {
              if (e?.target?.id === 'workspace-dropdown') {
                return;
              }
              if (isTabMenuOpen) onTabMenuClick(false);
            }}
          >
            <div
              className={cs(
                'bg-white list-none absolute opacity-0 ' +
                  'invisible pointer-events-none overflow-y-scroll',
                styles.tabWrapper__tabSelectorList,
                {
                  [styles.tabWrapper__tabSelectorOpen]: isTabMenuOpen,
                },
              )}
            >
              {tabs.map((tab: ITabProps, index) =>
                tab?.accessor === active ? (
                  <TabButton
                    key={index}
                    active={tab?.accessor === active}
                    tab={tab}
                    onTabClick={onTabClick}
                  />
                ) : null,
              )}
            </div>
          </OutsideClickHandler>
        </div>
      ) : (
        <div className={cs(className)}>
          <div className="flex items-center fixed top-[80px] bg-white z-10">
            {tabs.map((tab: ITabProps, index) =>
              tab?.accessor === active ? (
                <TabButton
                  key={index}
                  active={tab?.accessor === active}
                  tab={tab}
                  onTabClick={onTabClick}
                />
              ) : null,
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Tabs;

Tabs.defaultProps = {
  active: '',
  tabs: [],
  children: null,
};
