import cityList from './CityList';
import { Option } from '../foundation/ui/atoms/Dropdown/Dropdown.types';

export const tabs = [
  { accessor: 'office-suite', title: 'Office Suite', label: 'office suites' },
  { accessor: 'location', title: 'Location', label: 'locations' },
  { accessor: 'building', title: 'Building', label: 'buildings' },
];

export const getSelectedTab = (activeTab: string) => {
  return tabs.find(tab => tab.accessor === activeTab);
};

const extractChildren = i => i.children;

export const treeToFlatList = (children: Option[]) =>
  Array.prototype.concat.apply(
    children,
    children.map(x => treeToFlatList(extractChildren(x) || [])),
  );

export const flatListToTree = list => {
  const data = [...list];
  const map: any = {};
  let node: any;
  const roots: any = [];

  for (let i = 0; i < list.length; i += 1) {
    map[data[i].term_id] = i; // initialize the map
    data[i].children = []; // initialize the children
  }

  for (let i = 0; i < list.length; i += 1) {
    node = { ...list[i] };
    if (node.parent !== 0) {
      // if you have dangling branches check that map[node.parentId] exists
      data[map[node.parent]].children.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
};

export const getChildList = (list, child) => {
  return list.filter(l => l.parent === child);
};

export const getFormattedOptions = (
  options: Option[],
  labelField: string,
  valueField: string,
  parent = null,
  grandParent = null,
) => {
  return options.map(opt => ({
    label: opt[labelField],
    value: opt[valueField],
    children: getFormattedOptions(
      opt.children || [],
      labelField,
      valueField,
      opt[valueField],
      parent,
    ),
    parent,
    grandParent,
  }));
};

export const getFilterData = (
  activeTab,
  amenities,
  neighborhood,
  sqft,
  records,
  city,
) => {
  return amenities?.length ||
    neighborhood?.length ||
    (city && city?.neighborhoodId) ||
    (activeTab === 'office-suite' && !!(sqft && sqft?.value)) ||
    activeTab === 'office-suite'
    ? records?.data?.filter(
        d1 =>
          (amenities && amenities.length
            ? amenities?.findIndex((amenity: any) =>
                d1.amenity.map(a => a.term_id).includes(amenity?.value),
              ) > -1
            : true) &&
          (neighborhood && neighborhood.length
            ? neighborhood.findIndex((neighbor: any) =>
                d1.neighborhood.map(d => d.term_id).includes(neighbor.value),
              ) > -1
            : true) &&
          (city && city?.neighborhoodId
            ? d1.neighborhood.findIndex(
                (neighbor: any) => neighbor.term_id === city?.neighborhoodId,
              ) > -1
            : true) &&
          (activeTab === 'office-suite' && sqft && sqft?.value
            ? d1.sqft && d1.sqft.term_id === sqft?.value
            : true),
      ) || []
    : records?.data || [];
};

export const getQueryString = (
  activeTab,
  key: string,
  value,
  { amenities, neighborhood, sqft, city },
) => {
  const selectedAmenities = (key === 'amenities' ? value : amenities).length
    ? (key === 'amenities' ? value : amenities)
        .map(amenity => amenity.value)
        .toString()
    : '';
  const selectedNeighborhood =
    key !== 'city' && (key === 'neighborhood' ? value : neighborhood).length
      ? (key === 'neighborhood' ? value : neighborhood)
          .map((neighbor: any) => neighbor.value)
          .toString()
      : '';
  const selectedSqft = (key === 'sqft' ? value : sqft)
    ? ((key === 'sqft' ? value : sqft) || {})?.value
    : '';
  const selectedCity = (key === 'city' ? value : city)
    ? ((key === 'city' ? value : city) || {})?.value
    : '';

  return `?view=${key === 'tab' ? value : activeTab}${
    selectedAmenities ? `&amenities=${selectedAmenities}` : ''
  }${selectedCity ? `&city=${selectedCity}` : ''}${
    selectedNeighborhood ? `&neighborhood=${selectedNeighborhood}` : ''
  }${
    (key === 'tab' ? value : activeTab) === 'office-suite' && selectedSqft
      ? `&sqft=${selectedSqft}`
      : ''
  }`;
};

export const haversineDistance = (mk1, mk2) => {
  const R = 3958.8; // Radius of the Earth in miles
  const rlat1 = mk1.lat * (Math.PI / 180); // Convert degrees to radians
  const rlat2 = mk2.lat * (Math.PI / 180); // Convert degrees to radians
  const difflat = rlat2 - rlat1; // Radian difference (latitudes)
  const difflon = (mk2.lng - mk1.lng) * (Math.PI / 180); // Radian difference (longitudes)

  const d =
    2 *
    R *
    Math.asin(
      Math.sqrt(
        Math.sin(difflat / 2) * Math.sin(difflat / 2) +
          Math.cos(rlat1) *
            Math.cos(rlat2) *
            Math.sin(difflon / 2) *
            Math.sin(difflon / 2),
      ),
    );
  return d;
};

export const getCurrentTabFromUrl = () => {
  const url = process.env.REACT_APP_ACTIVE_TAB || window.location.hostname;

  if (url.indexOf('office-suite') > -1) {
    return 'office-suite';
  }
  if (url.indexOf('building') > -1) {
    return 'building';
  }
  if (url.indexOf('building') > -1) {
    return 'building';
  }
  return 'location';
};

export const getCurrentParams = (
  location,
  amenityData,
  neighborhoodData,
  sqftData,
) => {
  const { search: searchParam } = location;

  const params = searchParam
    .slice(1)
    .split('&')
    .map((a: string) => a.split('='))
    .reduce(
      (acc: Record<string, string>, cur: string[]) => ({
        ...acc,
        [cur[0]]: cur[1],
      }),
      {},
    );

  const cityDetails = params.city
    ? cityList.find(c => c.value === params.city)
    : null;

  const currentParams = {
    // view: params.view || 'location',
    view: getCurrentTabFromUrl(),
    latitude: +(params?.lat || cityDetails?.lat || 32.8177793),
    // eslint-disable-next-line no-nested-ternary
    longitude: +(params?.lng || cityDetails?.lng || -96.8716282),
    amenityList:
      (params?.amenities?.length &&
        amenityData?.data?.filter(
          amenity =>
            params?.amenities?.split(',').indexOf(amenity.term_id.toString()) >
            -1,
        )) ||
      [],
    neighborhoodList:
      // eslint-disable-next-line no-nested-ternary
      (params?.neighborhood?.length
        ? neighborhoodData?.data?.filter(
            neighbor =>
              params?.neighborhood
                ?.split(',')
                .indexOf(neighbor.term_id.toString()) > -1,
          )
        : cityDetails && cityDetails.neighborhoodId
        ? neighborhoodData?.data?.filter(
            neighbor =>
              cityDetails.neighborhoodId === neighbor.term_id ||
              cityDetails.neighborhoodId === neighbor.parent,
          )
        : []) || [],
    sqftList:
      (params?.sqft?.length &&
        sqftData?.data?.find(s => params?.sqft === s.term_id.toString())) ||
      {},
    zoomParams: +(params?.z || 3.5),
    cityParams: cityDetails,
  };

  return currentParams;
};
