import React from 'react';
// eslint-disable-next-line import/no-unresolved
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import './index.css';

import reportWebVitals from './reportWebVitals';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'rc-slider/assets/index.css';
import MainView from './view/MainView';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
const client = new QueryClient();
root.render(
  <QueryClientProvider client={client}>
    <React.StrictMode>
      <BrowserRouter>
        <MainView />
      </BrowserRouter>
    </React.StrictMode>
  </QueryClientProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
