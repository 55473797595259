import React, {
  SyntheticEvent,
  useRef,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { MapRef } from 'react-map-gl';
import cs from 'classnames';
import { useQuery } from 'react-query';
import * as geolib from 'geolib';
import { useLocation, useNavigate } from 'react-router-dom';
import Geolocation from 'react-geolocation';
import { getData } from '../action/services';
import mainViewStyle from './MainView.module.scss';
import Header from '../foundation/ui/atoms/Header/Header';
import {
  getCurrentParams,
  getCurrentTabFromUrl,
  getFilterData,
  getQueryString,
  getSelectedTab,
} from '../helpers/common';
import MapView from '../foundation/ui/molecules/MapView';
import ListView from '../foundation/ui/molecules/ListView';
// import cityList from '../helpers/CityList';

const MainView = () => {
  const mapRef = useRef<MapRef>();
  const location = useLocation();

  // const currentParams = useState(getCurrentParams(location));

  const [activeTab, setActiveTab] = useState(getCurrentTabFromUrl());
  const [selectedLocation, setSelectedLocation] = useState<any>(null);
  const [latLngValue, setLatLngValue] = useState({
    latitude: 32.8177793,
    longitude: -96.8716282,
  });
  const [currentLocation, setCurrentLocation] = useState(null);
  const [amenities, setAmenities] = useState([]);
  const [city, setCity] = useState<any>(null);
  const [neighborhood, setNeighborhood] = useState([]);
  const [sqft, setSqft] = useState(null);
  const navigate = useNavigate();
  const [zoom, setZoom] = useState(3.5);

  const { data, isLoading } = useQuery(['lists', activeTab], () =>
    getData(activeTab),
  );

  const { data: amenityData } = useQuery(['amenityLists', activeTab], () =>
    getData(`taxonomies-cpt/amenity/${activeTab}`),
  );

  const { data: neighborhoodData } = useQuery(
    ['neighborhoodLists', activeTab],
    () => getData(`taxonomies-cpt/neighborhood/${activeTab}`),
  );

  const { data: sqftData } = useQuery(['sqftLists', activeTab], () =>
    getData(`taxonomies-cpt/sqft/${activeTab}`),
  );

  useEffect(() => {
    const {
      view,
      latitude,
      longitude,
      amenityList,
      neighborhoodList,
      sqftList,
      zoomParams,
      cityParams,
    } = getCurrentParams(location, amenityData, neighborhoodData, sqftData);
    setActiveTab(view);
    setLatLngValue({
      latitude,
      longitude,
    });
    // @ts-ignore
    setCity(cityParams);
    // @ts-ignore
    setZoom(zoomParams);
    setAmenities(
      amenityList &&
        amenityList.map((d: any) => ({ value: d.term_id, label: d.name })),
    );
    setNeighborhood(
      neighborhoodList &&
        neighborhoodList.map((d: any) => ({ value: d.term_id, label: d.name })),
    );
    // @ts-ignore
    setSqft(sqftList ? { value: sqftList.term_id, label: sqftList.name } : {});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, amenityData, neighborhoodData, sqftData]);

  useEffect(() => {
    const { latitude = 0, longitude = 0 } = latLngValue;
    if (!latitude && !longitude && data && data.data && data.data.length) {
      const latLongList = data.data.map(locationData => {
        const loc = locationData?.acf?.location || {};
        return {
          longitude: loc && loc?.lng ? loc?.lng : longitude,
          latitude: loc && loc?.lat ? loc?.lat : latitude,
        };
      });

      if (latLongList.length) {
        const centerPoint = geolib.getCenter(latLongList);
        setLatLngValue({
          // @ts-ignore
          latitude:
            centerPoint && centerPoint?.latitude ? centerPoint?.latitude : 0,
          longitude:
            centerPoint && centerPoint?.longitude ? centerPoint?.longitude : 0,
        });
      }
    } else {
      const { latitude: paramsLatitude, longitude: paramLongitude } =
        getCurrentParams(location, amenityData, neighborhoodData, sqftData);
      if (latitude !== paramsLatitude && longitude !== paramLongitude) {
        setLatLngValue({
          latitude: paramsLatitude,
          longitude: paramLongitude,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data, activeTab]);

  useEffect(() => {
    // @ts-ignore
    if (city && city?.lat && city?.lng && mapRef?.current) {
      // @ts-ignore
      mapRef?.current?.flyTo({
        // @ts-ignore
        center: [city?.lng, city?.lat],
        duration: 1000,
        zoom: 11,
      });
    }
  }, [city]);

  const handleSelectLocationClick = (e: SyntheticEvent, details) => {
    const { location: selectedLocationDetails } = details;
    setSelectedLocation(selectedLocationDetails);
    const id = selectedLocationDetails?.id;
    setTimeout(() => {
      if (document && document.getElementById(`sideCard-${id}`) !== null) {
        // @ts-ignore
        document
          .getElementById(`sideCard-${id}`)
          .scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 500);
  };

  const handleSelectItemClick = details => {
    setSelectedLocation(
      // @ts-ignore
      selectedLocation?.id === details?.id ? null : details,
    );
    if (selectedLocation?.id !== details?.id) {
      const loc = details?.acf?.location || {};
      const coords = loc && loc.lng && loc.lat ? [loc.lng, loc.lat] : [0, 0];
      if (coords) {
        // @ts-ignore
        mapRef?.current?.flyTo({
          center: [coords[0], coords[1]],
          duration: 1000,
          zoom: 11,
        });
      }
    }
  };

  const handleFilterChange = (key: string, value) => {
    navigate({
      search: getQueryString(activeTab, key, value, {
        amenities,
        neighborhood,
        sqft,
        city,
      }),
    });
  };

  const handleClearFilters = () => {
    navigate({
      search: `?view=${activeTab}`,
    });
  };

  const filterData = useMemo(
    () => getFilterData(activeTab, amenities, neighborhood, sqft, data, city),
    [activeTab, amenities, neighborhood, sqft, data, city],
  );

  const selectedTabTitle = useMemo(
    () => getSelectedTab(activeTab)?.label || '',
    [activeTab],
  );

  useEffect(() => {
    const currentTab = getSelectedTab(activeTab);
    document.title = `${
      currentTab?.label ? `${currentTab?.title}s | ` : ''
    }Common Desk`;
  }, [activeTab]);

  const geoSuccess = position => {
    setCurrentLocation({
      // @ts-ignore
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    });
  };

  return (
    <>
      {/* <Header /> */}
      <div className="h-screen overflow-hidden mobile:overflow-scroll mobile:relative">
        <Header
          activeTab={activeTab}
          amenities={amenities}
          neighborhood={neighborhood}
          city={city}
          sqft={sqft}
          amenityData={amenityData}
          neighborhoodData={neighborhoodData}
          sqftData={sqftData}
          navigate={navigate}
          handleFilterChange={handleFilterChange}
          setSelectedLocation={setSelectedLocation}
          handleClearFilters={handleClearFilters}
        />
        <Geolocation onSuccess={geoSuccess} />
        <div
          id="scroll"
          className="flex justify-between w-full mobile:h-[calc(100vh)] mt-[148px] mobile:mt-[208px] mobile:block mobile:overflow-scroll flex-row-reverse"
        >
          <MapView
            data={getFilterData(
              activeTab,
              amenities,
              neighborhood,
              sqft,
              data,
              city,
            )}
            mapRef={mapRef}
            selectedLocation={selectedLocation}
            handleSelectLocationClick={handleSelectLocationClick}
            latLngValue={latLngValue}
            zoom={zoom}
          />
          <div
            className={cs(
              'w-3/5 pb-4 overflow-auto h-[calc(100vh_-_9.6rem)] mt-1 mobile:block mobile:relative' +
                'mobile:bottom-0 mobile:bg-white mobile:z-10 mobile:h-auto mobile:w-[calc(100vw)]' +
                ' mobile:top-[12px] mobile:overflow-visible mobile:mt-0',
              mainViewStyle.contentContainer,
            )}
          >
            <ListView
              filterData={filterData}
              selectedTabTitle={selectedTabTitle}
              isLoading={isLoading}
              selectedLocation={selectedLocation}
              handleSelectItemClick={handleSelectItemClick}
              activeTab={activeTab}
              currentLocation={currentLocation}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MainView;
