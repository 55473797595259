import React, { useMemo } from 'react';
import cs from 'classnames';
import { useMedia } from 'react-use';
import Dropdown from '../Dropdown';
// import cityList from '../../../../helpers/CityList';
// import Slider from '../Slider';
import Tabs from '../Tabs/Tabs';
import OpenButton from '../OpenButton';
import { tabs, flatListToTree } from '../../../../helpers/common';
import CommonDeskLogo from '../../../../assets/CommonDeskLogo';

const Header = ({
  activeTab,
  amenities,
  neighborhood,
  sqft,
  amenityData,
  neighborhoodData,
  sqftData,
  navigate,
  // city,
  setSelectedLocation,
  handleFilterChange,
  handleClearFilters,
}: any) => {
  const isMobileView = useMedia('(max-width: 768px)');

  const onTabChange = (tab: string | number) => {
    // setAnimate(false);
    setSelectedLocation(null);
    navigate({
      search: `?view=${tab}`,
    });
  };

  const cityOptions = useMemo(
    () =>
      neighborhoodData?.data
        ? flatListToTree(Object.values(neighborhoodData?.data))
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [neighborhood],
  );

  return (
    <div className="fixed top-0 z-10 w-full">
      <div
        className={cs('h-[80px] bg-[#121B2A] -mx-12 flex items-center', {
          'px-[4.5rem]': isMobileView,
          'px-24': !isMobileView,
        })}
      >
        <a href="https://commondeskprod.wpengine.com/">
          <CommonDeskLogo />
        </a>
      </div>
      <div
        className={cs(
          'flex-col justify-center w-full shadow-[0_5px_10px_-10px_rgba(0,0,0,0.3)] ' +
            'bg-white',
          {
            'block px-0 justify-center': isMobileView,
            'flex pl-12 pr-7 min-h-[68px] max-h-24': !isMobileView,
          },
        )}
      >
        <Tabs
          title="Workspace Type"
          className="relative"
          tabs={tabs}
          active={activeTab}
          onChange={onTabChange}
        />
        <div
          className={cs('flex items-center gap-2', {
            'h-[64px] justify-start px-4 relative': isMobileView,
            'justify-end': !isMobileView,
          })}
        >
          {!isMobileView ? (
            <OpenButton
              onClick={handleClearFilters}
              className="flex items-center"
              label="Clear Filters"
              isFilterButton
            />
          ) : null}
          <Dropdown
            isMultiSelect
            filterKey="amenities"
            options={amenityData?.data ? Object.values(amenityData?.data) : []}
            labelField="name"
            valueField="term_id"
            label="Amenities"
            value={amenities}
            onChange={handleFilterChange}
            wrapperClassName={!isMobileView ? 'ml-4' : ''}
          />
          {/* <Dropdown
          filterKey="city"
          options={cityList}
          label="City"
          value={city}
          onChange={handleFilterChange}
          isMultiSelect={false}
          labelField="label"
          valueField="value"
          wrapperClassName={!isMobileView ? 'ml-4' : ''}
        /> */}
          <Dropdown
            filterKey="neighborhood"
            options={cityOptions}
            label="Neighborhood"
            value={neighborhood}
            onChange={handleFilterChange}
            isMultiSelect
            labelField="name"
            valueField="term_id"
            isGrouping
            wrapperClassName={!isMobileView ? 'ml-4' : ''}
          />
          {activeTab === 'office-suite' ? (
            <Dropdown
              filterKey="sqft"
              options={sqftData?.data ? Object.values(sqftData?.data) : []}
              label="Sqft"
              value={sqft}
              onChange={handleFilterChange}
              isMultiSelect={false}
              labelField="name"
              valueField="term_id"
              wrapperClassName={!isMobileView ? 'ml-4' : ''}
            />
          ) : null}
          {isMobileView ? (
            <OpenButton
              onClick={handleClearFilters}
              className={cs('flex items-center', {})}
              label="Clear"
              isFilterButton
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Header;
