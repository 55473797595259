import React from 'react';
import cs from 'classnames';
import { useMedia } from 'react-use';
import Loader from '../../atoms/Loader';
import Card from '../../atoms/Card/Card';

const ListView = ({
  filterData,
  selectedTabTitle,
  isLoading,
  selectedLocation,
  handleSelectItemClick,
  activeTab,
  currentLocation,
}) => {
  const isMobileView = useMedia('(max-width: 768px)');

  return (
    <>
      <div
        className={cs('text-gray-light6 text-sm bg-white z-[2]', {
          'flex items-center px-6 pt-5 py-4': isMobileView,
          'justify-between': isMobileView && filterData && filterData.length,
          'justify-end': isMobileView && !(filterData && filterData.length),
          'p-0': !isMobileView && !(filterData && filterData.length),
          'pt-5 py-4 px-12': !isMobileView && filterData && filterData.length,
        })}
      >
        {filterData && filterData.length
          ? `Viewing ${filterData?.length} ${selectedTabTitle}`
          : ''}
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center w-full h-[calc(100%_-_1rem)]">
          <Loader color="#d3d3d3" height={50} width={50} />
        </div>
      ) : (
        <>
          {filterData && filterData.length ? (
            <div
              className={cs(
                'flex flex-row px-6 flex-wrap justify-between gap-1 mobile:flex-nowrap mobile:flex-col',
                {
                  'px-6': isMobileView,
                },
              )}
            >
              {/* @ts-ignore */}
              {filterData && filterData.length
                ? filterData.map((d, index) => (
                    <Card
                      data={d}
                      key={index}
                      selectedLocation={selectedLocation}
                      handleSelectItemClick={handleSelectItemClick}
                      activeTab={activeTab}
                      currentLocation={currentLocation}
                    />
                  ))
                : null}
            </div>
          ) : (
            <div className="flex justify-center items-center text-center w-full h-[calc(100%_-_1rem)]">
              <h3>No Result Found!</h3>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ListView;
